'use client';

import { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { getProductIdFromHandle } from '../../utils';
import Icon from '../Icon/Icon';
import styles from './reviewStarRating.module.scss';
import { useOkendoReviews } from './reviewUtils';

interface OkendoStarRatingProps {
  onClick: () => void;
  productHandle: string;
  showArrow?: boolean;
}

export function ReviewStarRating({
  onClick,
  productHandle,
  showArrow = true,
}: OkendoStarRatingProps): JSX.Element | null {
  const widgetContainer = useRef(null);

  const [productId, setProductId] = useState<string | undefined>();

  const { reviewCount } = useOkendoReviews(widgetContainer, productId);

  useEffect(() => {
    if (productHandle) {
      getProductIdFromHandle(productHandle).then((id) => {
        setProductId(id);
      });
    }
  }, [productHandle]);

  return productId ? (
    <div
      className={clsx(
        'relative flex flex-row items-center',
        !reviewCount ? styles.hide : '',
      )}
    >
      <button
        aria-label='reviews-button'
        className={clsx(styles.clickable)}
        onClick={onClick}
        type='button'
      />
      <div
        className={styles.autoCursor}
        data-oke-reviews-product-id={`shopify-${productId}`}
        data-oke-star-rating
        data-testid={`shopify-${productId}`}
        id={`shopify-${productId}`}
        ref={widgetContainer}
        style={{ lineHeight: '0' }}
      />
      {reviewCount && showArrow ? (
        <div className='flex items-center pl-2 text-sm'>
          <Icon
            alt='arrow right open reviews modal'
            size='small'
            variant='arrow_right'
          />
        </div>
      ) : null}
    </div>
  ) : null;
}
export default ReviewStarRating;
