import type { IconProps } from '../../../components/Icon/Icon.util';

export const IcCaretUp = ({
  title,
  width = 32,
  height = 32,
  fill = 'none',
  role = 'presentation',
}: IconProps): React.ReactNode => {
  const viewBoxSize = 32;

  return (
    <svg
      fill={fill}
      focusable='false'
      height={height}
      role={role}
      viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}
      width={width}
      xmlns='http://www.w3.org/2000/svg'
    >
      {title ? <title>{title}</title> : null}

      <path d='M16.2959 8.0272C16.5889 8.08161 16.8687 8.21763 17.0956 8.43526L28.6975 19.5633C29.1008 19.9502 29.1008 20.5774 28.6975 20.9643L27.9202 21.7098C27.5169 22.0967 26.8629 22.0967 26.4595 21.7098L16 11.6776L5.54051 21.7098C5.13713 22.0967 4.48312 22.0967 4.07975 21.7098L3.30253 20.9644C2.89915 20.5775 2.89916 19.9502 3.30253 19.5633L14.9044 8.43526C15.2826 8.07254 15.8076 7.93652 16.2959 8.0272Z' />
    </svg>
  );
};

export default IcCaretUp;
